export const STORAGE_KEYS = {
  darkMode: "dark-mode",
  appLang: "app-lang",
};

export const QUERY_KEYS = {
  userData: "userData",
  uadTaxonomy: "uadTaxonomy",
  appSettings: "appSettings",
  userRoles: "userRoles",
  clientList: "clientList",
  isAdminUser: "isAdminUser",
  excelReport: "excelReport",
};

export const ROUTES = {
  getVehicleItemPath: ({
    offerId,
    actionType,
    hasInvoice,
    hasPolicy,
  }: {
    offerId?: string | null;
    hasInvoice?: boolean;
    hasPolicy?: boolean;
    actionType?: "copy" | "create" | "preview" | "open";
  }) => {
    const queryParams = new URLSearchParams();

    if (offerId) {
      queryParams.set("offerId", offerId);
    }
    if (hasInvoice) {
      queryParams.set("hasInvoice", "true");
    }

    if (actionType) {
      queryParams.set("actionType", actionType);
    }
    if (hasPolicy) {
      queryParams.set("hasPolicy", "true");
    }
    return "/vehicle?" + queryParams;
  },
};
