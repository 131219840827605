import { CommandBarButton } from "@fluentui/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useExportExcel } from "../../../hooks/data/mutations/useExportExcel";
import { useExportPdf } from "../../../hooks/data/mutations/useExportPdf";
import { useSaveResults } from "../../../hooks/data/mutations/useSaveResults";
import useAppTheme from "../../../hooks/useAppTheme";
import { ECheckedValue } from "../../../library/types";
import { openLinkInNewTab } from "../../../library/utils";
import { getQuestionnaireData } from "../../../pages/MainPage/data/data";
import {
  contactPersonsState,
  hasContradictoryAnswersState,
  resultIdState,
  resultsState,
  selectedClientState,
} from "../../../store";
import { prepareSaveResultsPayload } from "./helpers";
import { useGetIsAdminUser } from "../../../hooks/data/queries/useGetIsAdminUser";
import { GenerateExcelReport } from "./GenerateExcelReport/GenerateExcelReport";

export function AppSubheader() {
  const { t } = useTranslation();
  const theme = useAppTheme();

  const { data: isAdminUser } = useGetIsAdminUser();

  const [resultId, setResultId] = useAtom(resultIdState);
  const [selectedClient, seSelectedClient] = useAtom(selectedClientState);
  const [contactPersons, setContactPersons] = useAtom(contactPersonsState);
  const [results, setResults] = useAtom(resultsState);
  const [hasContradictoryAnswers, setHasContradictoryAnswers] = useAtom(
    hasContradictoryAnswersState
  );

  const [excelReport, setExcelReport] = useState(null);
  const [pdfReport, setPdfReport] = useState(null);

  const { mutate: onSaveResults, isLoading: saveResultInProgress } =
    useSaveResults();

  const { mutate: onExportExcel, isLoading: exportExcelInProgress } =
    useExportExcel();
  const { mutate: onExportPdf, isLoading: exportPdfInProgress } =
    useExportPdf();

  const excelReportUrl = excelReport?.url;
  const pdfReportUrl = pdfReport?.url;

  const isLoading =
    saveResultInProgress || exportExcelInProgress || exportPdfInProgress;

  const questionnaireData = getQuestionnaireData(t);

  const allFilled =
    Object.keys(results).length === questionnaireData?.length &&
    Object.values(results).every((obj) => obj.hasOwnProperty("evaluation")) &&
    contactPersons.trim() &&
    !hasContradictoryAnswers;

  const handleSubmit = () => {
    const formattedData = prepareSaveResultsPayload(results, questionnaireData);

    const payload = {
      ...formattedData,
      contactPersons,
    };

    onSaveResults(
      { clientId: selectedClient.id, payload },
      {
        onSuccess: ({ data }) => {
          toast.success(t("esg.saveResultSuccess.label"));

          setResultId(data);
        },
        onError: (error: any) => {
          toast.error(error?.message || "Error!");
        },
      }
    );
  };

  const onResetQuestionnaire = () => {
    seSelectedClient({});
    setResults({});
    setResultId("");
    setExcelReport(null);
    setPdfReport(null);
    setContactPersons("");
  };

  useEffect(() => {
    setResultId("");
    setExcelReport(null);
    setPdfReport(null);
  }, [selectedClient]);

  useEffect(() => {
    if (results["1"] && results["2"]) {
      const haveStrategyAForClimateNeutrality =
        results["1"]?.answers["2"] === ECheckedValue.Yes;

      const dontHaveStrategyForClimateNeutrality =
        results["1"]?.answers["1"] === ECheckedValue.No;

      if (haveStrategyAForClimateNeutrality) {
        const noPlaningInvestment =
          results["2"]?.answers["1"] === ECheckedValue.No;

        if (noPlaningInvestment) {
          // setHasContradictoryAnswers(true);

          toast.info(t("esg.contradictoryAnswerNote1.label"), {
            toastId: "note1",
            autoClose: false,
          });
          return;
        } else {
          // setHasContradictoryAnswers(false);
        }
      }
      if (dontHaveStrategyForClimateNeutrality) {
        const planingInvestment =
          results["2"]?.answers["1"] === ECheckedValue.Yes;
        if (planingInvestment) {
          toast.info(t("esg.contradictoryAnswerNote2.label"), {
            toastId: "note2",
            autoClose: false,
          });
          // setHasContradictoryAnswers(true);
          return;
        } else {
          // setHasContradictoryAnswers(false);
        }
      }
    }
  }, [results, setHasContradictoryAnswers, t]);

  return (
    <NavigationBar>
      {!!Object.keys(selectedClient).length && (
        <CommandBarButton
          disabled={isLoading}
          iconProps={{
            iconName: isLoading ? "ProgressRingDots" : "ExcelLogoInverse",
          }}
          styles={{
            root: {
              height: "44px",
              marginLeft: 20,
              background: "transparent",
              color: theme.palette.black,
              "&&:hover": {
                background: "transparent",
              },
            },
          }}
          text={t("greco.reset")}
          onClick={onResetQuestionnaire}
        />
      )}
      {isAdminUser && <GenerateExcelReport isLoading={isLoading} />}
      <Wrap>
        {resultId && (
          <>
            <CommandBarButton
              disabled={isLoading}
              iconProps={{
                iconName: isLoading ? "ProgressRingDots" : "ExcelDocument",
              }}
              styles={{
                root: {
                  height: "44px",
                  background: "transparent",
                  color: theme.palette.black,
                  "&&:hover": {
                    background: "transparent",
                  },
                },
              }}
              text={
                excelReportUrl
                  ? t("esg.downloadExcel.label")
                  : t("greco.exportExcel")
              }
              onClick={() => {
                if (excelReportUrl) {
                  openLinkInNewTab(excelReportUrl);

                  return;
                }
                // setExportExcel(true);
                onExportExcel(
                  {
                    clientId: selectedClient.id,
                    resultId: resultId,
                  },
                  {
                    onSuccess: (res) => {
                      setExcelReport(res.data);
                    },
                  }
                );
              }}
            />
            <CommandBarButton
              disabled={isLoading}
              styles={{
                root: {
                  height: "44px",
                  background: "transparent",
                  color: theme.palette.black,
                  "&&:hover": {
                    background: "transparent",
                  },
                },
              }}
              iconProps={{
                iconName: isLoading ? "ProgressRingDots" : "PDF",
              }}
              text={
                pdfReportUrl ? t("esg.downloadPdf.label") : t("greco.exportPDF")
              }
              onClick={() => {
                if (pdfReportUrl) {
                  openLinkInNewTab(pdfReportUrl);
                  return;
                }
                onExportPdf(
                  {
                    clientId: selectedClient.id,
                    resultId: resultId,
                  },
                  {
                    onSuccess: (res) => {
                      setPdfReport(res.data);
                    },
                  }
                );
              }}
            />
          </>
        )}
        {allFilled && !resultId && (
          <CommandBarButton
            disabled={isLoading}
            styles={{
              root: {
                height: "44px",
                background: "transparent",
                color: theme.palette.black,
                "&&:hover": {
                  background: "transparent",
                },
              },
            }}
            iconProps={{
              iconName: isLoading ? "ProgressRingDots" : "Save",
            }}
            text={t("esg.saveResults.label")}
            onClick={handleSubmit}
          />
        )}
      </Wrap>
    </NavigationBar>
  );
}

export const NavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme.palette.white};
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  position: sticky;
  top: 0;
  z-index: 333;
`;

const Wrap = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;
