import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import useAppTheme from "../../../../hooks/useAppTheme";
import ExcelReportDialog from "./ExcelReportDialog";

type Props = {
  isLoading: boolean;
};
export function GenerateExcelReport({ isLoading }: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const [excelDialogOpen, setExcelDialogOpen] = React.useState(false);

  const toggleIsDialogOpen = () => {
    setExcelDialogOpen((prev) => !prev);
  };
  return (
    <>
      <CommandBarButton
        disabled={isLoading}
        iconProps={{
          iconName: isLoading ? "ProgressRingDots" : "ExcelLogo",
        }}
        styles={{
          root: {
            height: "44px",
            marginLeft: 20,
            background: "transparent",
            color: theme.palette.black,
            "&&:hover": {
              background: "transparent",
            },
          },
        }}
        text={t("esg.generateExcelReport.label")}
        onClick={() => {
          setExcelDialogOpen(true);
        }}
      />
      {excelDialogOpen && (
        <ExcelReportDialog
          isOpen={excelDialogOpen}
          toggleOpen={toggleIsDialogOpen}
        />
      )}
    </>
  );
}
