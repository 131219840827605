import { Checkbox, Dialog, DialogType, Text } from "@fluentui/react";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import infoIcon from "../../../assets/Icon_Info.svg";
import useAppTheme from "../../../hooks/useAppTheme";
import {
  ECheckedValue,
  IQuestion,
  IQuestionnaire,
} from "../../../library/types";
import {
  resultIdState,
  resultsState,
  selectedClientState,
} from "../../../store";
import { getCheckedConfig } from "../utils";

type Props = {
  questionGroupName: string;
  questionGroupId: number;
  questionItemNo: number | string;
  questionGroupNo: number;
  currentLevel: number;
  questionLevels: number[];
  question: IQuestion;
  setQuestionLevels: (value) => void;
  hasNext: boolean;
};

export function QuestionItem({
  questionLevels,
  question,
  setQuestionLevels,
  hasNext,
  currentLevel,
  questionGroupNo,
  questionItemNo,
  questionGroupId,
  questionGroupName,
}: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<ECheckedValue | "">("");
  const setResults = useSetAtom(resultsState);
  const selectedClient = useAtomValue(selectedClientState);

  const resultId = useAtomValue(resultIdState);

  const actionDisabled = !!resultId;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleIsDialogOpen = () => {
    setIsDialogOpen((prev) => !prev);
  };

  const marginLeft = currentLevel * 50 + "px";

  const checkedData = getCheckedConfig(checked, question, theme);
  const checkedText = checkedData?.text;
  const checkedColor = checkedData?.color;

  useEffect(() => {
    setChecked("");
  }, [selectedClient]);

  return (
    <>
      {questionLevels.includes(question?.id) && (
        <Container marginLeft={marginLeft}>
          <TitleWrap>
            <Title>{`${questionGroupNo}.${questionItemNo} ${question.question}`}</Title>
            {question.questionExplained && (
              <img
                onClick={toggleIsDialogOpen}
                src={infoIcon}
                alt="info"
                style={{
                  width: 40,
                  height: 50,
                  cursor: "pointer",
                  // transform: "scale(0.7)",
                }}
              />
            )}
          </TitleWrap>

          <CheckboxSection marginLeft={marginLeft}>
            <Checkbox
              styles={{
                text: {
                  color: theme.palette.black,
                },
              }}
              disabled={actionDisabled}
              label={t("esg.yes.label")}
              checked={checked === ECheckedValue.Yes}
              onChange={(_, checked) => {
                if (checked) {
                  setChecked(ECheckedValue.Yes);
                  if (hasNext) {
                    setQuestionLevels((prev) => {
                      const include = prev.includes(question?.id + 1);

                      if (include) {
                        return prev.filter(
                          (level: any) => level <= question.id
                        );
                      }
                      return [...prev, question.id + 1];
                    });

                    setResults((prev: any) => {
                      return {
                        ...prev,
                        [questionGroupId]: {
                          answers: {
                            ...(prev[questionGroupId]?.answers || {}),
                            [question.id]: ECheckedValue.Yes,
                          },
                        },
                      };
                    });
                  } else {
                    setResults((prev: IQuestionnaire) => {
                      return {
                        ...prev,
                        [questionGroupId]: {
                          question: question.question,
                          questionGroupName,
                          evaluation: question.yesAnswer.text,
                          statusColor: question.yesAnswer.statusColor,
                          questionExplained: question.questionExplained,
                          referenceText: question.yesAnswer.referenceText,
                          answers: {
                            ...(prev[questionGroupId]?.answers || {}),
                            [question.id]: ECheckedValue.Yes,
                          },
                        },
                      };
                    });
                  }
                } else {
                  setChecked("");
                  setQuestionLevels((prev) => {
                    return prev.filter((level: number) => level <= question.id);
                  });
                  setResults((prev) => {
                    delete prev[questionGroupId];
                    return {
                      ...prev,
                    };
                  });
                }
              }}
              name={ECheckedValue.Yes}
            />

            <Checkbox
              disabled={actionDisabled}
              label={t("esg.no.label")}
              name={ECheckedValue.No}
              checked={checked === ECheckedValue.No}
              styles={{
                text: {
                  color: theme.palette.black,
                },
              }}
              onChange={(_, checked) => {
                if (checked) {
                  setChecked(ECheckedValue.No);
                  setQuestionLevels((prev) => {
                    return prev.filter((level: number) => level <= question.id);
                  });

                  setResults((prev: IQuestionnaire) => {
                    const answerValues = Object.keys(
                      prev[questionGroupId]?.answers || {
                        [question.id]: ECheckedValue.No,
                      }
                    )
                      ?.filter((key) => {
                        return Number(key) <= currentLevel;
                      })
                      .reduce((obj, str) => {
                        obj[str] = prev[questionGroupId]?.answers[str];
                        return obj;
                      }, {});
                    answerValues[question.id] = ECheckedValue.No;

                    return {
                      ...prev,
                      [questionGroupId]: {
                        question: question.question,
                        questionGroupName,

                        evaluation: question.noAnswer.text,
                        statusColor: question.noAnswer.statusColor,
                        questionExplained: question.questionExplained,
                        referenceText: question.noAnswer.referenceText,

                        answers: answerValues,
                      },
                    };
                  });
                } else {
                  setChecked("");
                  setResults((prev) => {
                    delete prev[questionGroupId];
                    return {
                      ...prev,
                    };
                  });
                }
              }}
            />

            <Checkbox
              disabled={actionDisabled}
              label={t("esg.dontKnow.label")}
              name={ECheckedValue.DontKnow}
              styles={{
                text: {
                  color: theme.palette.black,
                },
              }}
              checked={checked === ECheckedValue.DontKnow}
              onChange={(_, checked) => {
                if (checked) {
                  setChecked(ECheckedValue.DontKnow);
                  setQuestionLevels((prev) => {
                    return prev.filter((level: number) => level <= question.id);
                  });
                  setResults((prev: IQuestionnaire) => {
                    const answerValues = Object.keys(
                      prev[questionGroupId]?.answers || {
                        [question.id]: ECheckedValue.DontKnow,
                      }
                    )
                      ?.filter((key) => {
                        return Number(key) <= question.id;
                      })
                      .reduce((obj, str) => {
                        obj[str] = prev[questionGroupId]?.answers[str];
                        return obj;
                      }, {});
                    answerValues[question.id] = ECheckedValue.DontKnow;

                    return {
                      ...prev,
                      [questionGroupId]: {
                        question: question.question,
                        questionGroupName,
                        evaluation: question.dontKnowAnswer.text,
                        statusColor: question.dontKnowAnswer.statusColor,
                        referenceText: question.dontKnowAnswer.referenceText,
                        questionExplained: question.questionExplained,
                        answers: answerValues,
                      },
                    };
                  });
                } else {
                  setChecked("");
                  setResults((prev) => {
                    delete prev[questionGroupId];
                    return {
                      ...prev,
                    };
                  });
                }
              }}
            />
          </CheckboxSection>
          {(checked === ECheckedValue.Yes && hasNext) ||
          !checkedText ||
          !checkedColor ? null : (
            <AnswerWrap checkedColor={checkedColor} marginLeft={marginLeft}>
              <AnswerText>{checkedText}</AnswerText>
            </AnswerWrap>
          )}
        </Container>
      )}
      {isDialogOpen && (
        <Dialog
          minWidth={800}
          maxWidth={800}
          dialogContentProps={{
            type: DialogType.normal,
            title: question.question,
            titleProps: {
              style: {
                color: theme.palette.neutralDark,
              },
            },
          }}
          styles={{
            main: {
              padding: 20,
              background: theme.palette.neutralLighterAlt,
            },
          }}
          hidden={!isDialogOpen}
          onDismiss={toggleIsDialogOpen}
        >
          <Text
            variant="xLargePlus"
            styles={{
              root: {
                fontWeight: "400",
                color: theme.palette.neutralDark,
              },
            }}
          >
            {question.questionExplained}
          </Text>
        </Dialog>
      )}
    </>
  );
}

const Container = styled("div")<{ marginLeft: string }>`
  text-align: left;
  margin-left: ${({ marginLeft }) => marginLeft};

  .ms-Checkbox:hover .ms-Checkbox-text {
    color: ${({ theme }) => theme.palette.black};
    opacity: 1;
  }
`;

const TitleWrap = styled("div")`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Title = styled("h3")<{ marginLeft?: string }>`
  text-align: left;
  color: ${({ theme }) => theme.palette.neutralPrimaryAlt};
`;

const CheckboxSection = styled("div")<{ marginLeft?: string }>`
  display: flex;
  margin-left: ${({ marginLeft }) => marginLeft};

  gap: 20px;
`;

const AnswerWrap = styled("div")<{
  marginLeft?: string;
  checkedColor?: string;
}>`
  display: flex;
  gap: 10px;
  margin-left: ${({ marginLeft }) => marginLeft};
  padding: 10px;
  margin-top: 20px;
  border-left: ${({ checkedColor }) => `4px solid ${checkedColor}`};
`;

const AnswerText = styled("div")<{ marginLeft?: string }>`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.palette.neutralPrimaryAlt};
`;
