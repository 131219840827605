import { IQuestion } from "../../library/types";
import { IStyledTheme } from "../../theme/types";

export const getCheckedConfig = (
  checked: string,
  question: IQuestion,
  theme: IStyledTheme
) => {
  switch (checked) {
    case "no":
      return {
        text: question?.noAnswer?.text,
        color: theme.palette[question?.noAnswer?.statusColor],
      };
    case "yes":
      return {
        text: question?.yesAnswer?.text,
        color: theme.palette[question?.yesAnswer?.statusColor],
      };
    case "dontKnow":
      return {
        text: question?.dontKnowAnswer?.text,
        color: theme.palette[question?.dontKnowAnswer?.statusColor],
      };
    default:
      return {
        text: "",
        color: "",
      };
  }
};
