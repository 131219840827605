import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LowResultIcon from "../../../../assets/Barometer_0.svg";
import HightResultIcon from "../../../../assets/Barometer_100.svg";
import MediumResultIcon from "../../../../assets/Barometer_50.svg";
import useAppTheme from "../../../../hooks/useAppTheme";
import { EStatusColor, IResultItem } from "../../../../library/types";

type Props = {
  result: IResultItem;
  resultNo: number;
};

function ResultItem({ result, resultNo }: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const color = theme.palette[result?.statusColor];

  const resultImgSrc = (() => {
    if (result.statusColor === EStatusColor.Red) return LowResultIcon;
    if (result.statusColor === EStatusColor.Yellow) return MediumResultIcon;
    if (result.statusColor === EStatusColor.Green) return HightResultIcon;
  })();

  return (
    <>
      <Container color={color}>
        <Wrap>
          <div>
            <ItemTitle>
              {resultNo + 1}.{result.questionGroupName}
            </ItemTitle>
            <img
              src={resultImgSrc}
              alt="info"
              style={{
                width: 180,
                cursor: "pointer",
                // transform: "scale(0.7)",
              }}
            />
          </div>
          <ResultValue color={color}>
            <q>{result.evaluation}</q>
          </ResultValue>
        </Wrap>

        {/* {result.questionExplained ? (
          <QuestionExplainedWrap>
            <div>{result.question}</div>
            <p>{result.questionExplained}</p>
          </QuestionExplainedWrap>
        ) : null} */}
        {result?.referenceText ? (
          <QuestionExplainedWrap>
            <div>{t("esg.recommendation.label")}</div>
            <p>{result?.referenceText}</p>
          </QuestionExplainedWrap>
        ) : null}
      </Container>
    </>
  );
}

export default ResultItem;

const Container = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
`;

const Wrap = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
`;

const ItemTitle = styled.div`
  display: flex;
  font-size: 20px;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.themePrimary};
  min-width: 120px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ResultValue = styled.div<{ color?: string }>`
  display: flex;
  flex: 1;
  font-size: 18px;
  height: 100%;
  padding-right: 10px;
  font-style: italic;
  text-align: left;
`;

const QuestionExplainedWrap = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 20px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.palette.neutralLighterAlt};
  text-align: left;
  div {
    font-size: 20px;
    font-weight: 600;
  }
`;
