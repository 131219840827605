import { atom } from "jotai";
import { STORAGE_KEYS } from "../library/shared";
import { LocalStorage } from "../service/storage";
import { IClient } from "../library/types";

const isDarkMode = LocalStorage.get(STORAGE_KEYS.darkMode);

export const darkModeState = atom(isDarkMode);

export const panelIsOpenState = atom(false);

export const selectedClientState = atom<IClient | any>({});

export const resultsState = atom({});
export const resultIdState = atom("");

export const contactPersonsState = atom("");
export const hasContradictoryAnswersState = atom(false);
