import { useMutation } from "react-query";
import api from "../../../service/api";

const url = process.env.REACT_APP_BASE_URL;

export const saveResults = async ({ clientId, payload }: any) => {
  return await api.post(`${url}/questionary/save/${clientId}`, payload);
};

export function useSaveResults() {
  return useMutation(saveResults, {
    onSuccess: () => {},
    onError: (error) => {
      console.log(error, "useSaveResults");
    },
  });
}
