import { EStatusColor, IQuestionnaire } from "../../../library/types";

export const getQuestionnaireData = (t): IQuestionnaire[] => [
  {
    id: 1,
    questionGroupName: t("esg.questionGroupName1.label"),

    questions: [
      {
        id: 1,
        question: t("esg.questionGroupName1.question1.label"),
        questionExplained: t("esg.questionGroupName1.question1Explained.label"),

        noAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName1.question1NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName1.question1NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName1.question1DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName1.question1DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 2,
        question: t("esg.questionGroupName1.question2.label"),
        questionExplained: ``,

        yesAnswer: {
          statusColor: EStatusColor.Green,
          text: t("esg.questionGroupName1.question2YesAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName1.question2YesAnswerRefText.label"
          ),
        },
        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName1.question2NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName1.question2NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName1.question2DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName1.question2DontKnowAnswerRefText.label"
          ),
        },
      },
    ],
  },
  {
    id: 2,
    questionGroupName: t("esg.questionGroupName2.label"),

    questions: [
      {
        id: 1,
        question: t("esg.questionGroupName2.question1.label"),
        questionExplained: ``,

        noAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName2.question1NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName2.question1NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName2.question1DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName2.question1DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 2,
        question: t("esg.questionGroupName2.question2.label"),
        questionExplained: ``,

        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName2.question2NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName2.question2NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName2.question2DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName2.question2DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 3,
        question: t("esg.questionGroupName2.question3.label"),
        questionExplained: ``,

        yesAnswer: {
          statusColor: EStatusColor.Green,
          text: t("esg.questionGroupName2.question3YesAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName2.question3YesAnswerRefText.label"
          ),
        },
        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName2.question3NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName2.question3NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName2.question3DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName2.question3DontKnowAnswerRefText.label"
          ),
        },
      },
    ],
  },
  {
    id: 3,
    questionGroupName: t("esg.questionGroupName3.label"),

    questions: [
      {
        id: 1,
        question: t("esg.questionGroupName3.question1.label"),
        questionExplained: t("esg.questionGroupName3.question1Explained.label"),
        noAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName3.question1NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName3.question1NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName3.question1DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName3.question1DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 2,
        question: t("esg.questionGroupName3.question2.label"),
        questionExplained: t("esg.questionGroupName3.question2Explained.label"),
        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName3.question2NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName3.question2NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName3.question2DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName3.question2DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 3,
        question: t("esg.questionGroupName3.question3.label"),
        questionExplained: t("esg.questionGroupName3.question3Explained.label"),
        yesAnswer: {
          statusColor: EStatusColor.Green,
          text: t("esg.questionGroupName3.question3YesAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName3.question3YesAnswerRefText.label"
          ),
        },
        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName3.question3NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName3.question3NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName3.question3DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName3.question3DontKnowAnswerRefText.label"
          ),
        },
      },
    ],
  },

  {
    id: 4,
    questionGroupName: t("esg.questionGroupName4.label"),

    questions: [
      {
        id: 1,
        question: t("esg.questionGroupName4.question1.label"),
        questionExplained: ``,
        noAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName4.question1NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName4.question1NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName4.question1DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName4.question1DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 2,
        question: t("esg.questionGroupName4.question2.label"),
        questionExplained: t("esg.questionGroupName4.question2Explained.label"),
        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName4.question2NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName4.question2NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName4.question2DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName4.question2DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 3,
        question: t("esg.questionGroupName4.question3.label"),
        questionExplained: ``,

        yesAnswer: {
          statusColor: EStatusColor.Green,
          text: t("esg.questionGroupName4.question3YesAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName4.question3YesAnswerRefText.label"
          ),
        },
        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName4.question3NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName4.question3NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName4.question3DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName4.question3DontKnowAnswerRefText.label"
          ),
        },
      },
    ],
  },
  {
    id: 5,
    questionGroupName: t("esg.questionGroupName5.label"),
    questions: [
      {
        id: 1,
        question: t("esg.questionGroupName5.question1.label"),
        questionExplained: t("esg.questionGroupName5.question1Explained.label"),
        noAnswer: {
          statusColor: EStatusColor.Green,
          text: t("esg.questionGroupName5.question1NoAnswerText.label"),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName5.question1DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName5.question1DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 2,
        question: t("esg.questionGroupName5.question2.label"),
        questionExplained: t("esg.questionGroupName5.question2Explained.label"),
        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName5.question2NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName5.question2NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName5.question2DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName5.question2DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 3,
        question: t("esg.questionGroupName5.question3.label"),
        questionExplained: t("esg.questionGroupName5.question3Explained.label"),
        yesAnswer: {
          statusColor: EStatusColor.Green,
          text: t("esg.questionGroupName5.question3YesAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName5.question3YesAnswerRefText.label"
          ),
        },
        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName5.question3NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName5.question3NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName5.question3DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName5.question3DontKnowAnswerRefText.label"
          ),
        },
      },
    ],
  },
  {
    id: 6,
    questionGroupName: t("esg.questionGroupName6.label"),
    questions: [
      {
        id: 1,
        question: t("esg.questionGroupName6.question1.label"),
        questionExplained: t("esg.questionGroupName6.question1Explained.label"),
        noAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName6.question1NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName6.question1NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Red,
          text: t("esg.questionGroupName6.question1DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName6.question1DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 2,
        question: t("esg.questionGroupName6.question2.label"),
        questionExplained: ``,

        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName6.question2NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName6.question2NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName6.question2DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName6.question2DontKnowAnswerRefText.label"
          ),
        },
      },
      {
        id: 3,

        question: t("esg.questionGroupName6.question3.label"),
        questionExplained: ``,

        yesAnswer: {
          statusColor: EStatusColor.Green,
          text: t("esg.questionGroupName6.question3YesAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName6.question3YesAnswerRefText.label"
          ),
        },
        noAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName6.question3NoAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName6.question3NoAnswerRefText.label"
          ),
        },
        dontKnowAnswer: {
          statusColor: EStatusColor.Yellow,
          text: t("esg.questionGroupName6.question3DontKnowAnswerText.label"),
          referenceText: t(
            "esg.questionGroupName6.question3DontKnowAnswerRefText.label"
          ),
        },
      },
    ],
  },
];
