export interface SupportedLanguage {
  isGrECoLanguage: boolean;
  code: string;
  isDeleted: boolean;
  id: number;
}

export type TaxonomyKey = any;

export type Taxonomy = {
  code: string;
  id: number;
};

export interface INotification {
  errors: any;
  fieldName: string;
  key: string;
  handleNotificationClick: (ev: any) => void;
  messageBarType: number;
  notify: INotify;
}

export interface INotify {
  notifyText: string;
  type: string;
  notifyCode: string;
  label: string;
}

export interface IErrorHandler {
  value: string;
  type: string;
  label: string;
  fieldName?: string;
  errors?: any;
}

export interface IUser {
  "@odata.context": string;
  businessPhones?: any[];
  displayName?: string;
  givenName?: string;
  jobTitle?: string | null;
  mail?: string | null;
  mobilePhone?: string;
  officeLocation?: string | null;
  preferredLanguage?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
}

export interface IQuestion {
  id: number;
  question: string;
  questionExplained: string;
  noAnswer: {
    statusColor: string;
    text: string;
    referenceText?: string;
  };
  dontKnowAnswer: {
    statusColor: string;
    text: string;
    referenceText?: string;
  };
  yesAnswer?: {
    statusColor: string;
    text: string;
    referenceText?: string;
  };
}

export interface IQuestionnaire {
  id: number;
  questionGroupName: string;
  questions: IQuestion[];
}

export enum ECheckedValue {
  Yes = "yes",
  No = "no",
  DontKnow = "dontKnow",
}

export interface IResults {
  [x: string]: IResultItem;
}

export interface IResultItem {
  question: string;
  questionGroupName: string;
  evaluation: string;
  statusColor: EStatusColor;
  questionExplained: string;
  // questions: string[];
  // questionsExplained: string[];
  answers: {
    [x: string]: string;
  };
  referenceText?: string;
}

export interface IClient {
  id: number;
  internalNumber: number;
  name: string;
  countryCodeId: number;
  isCompany: boolean;
}

export enum EStatusColor {
  Red = "red",
  Yellow = "yellow",
  Green = "green",
}
