import { UseQueryResult, useQuery } from "react-query";
import { QUERY_KEYS } from "../../../library/shared";
import api from "../../../service/api";

export const getIsAdminUser = async () => {
  const res = await api.get(`/user`);

  return res.data;
};

export function useGetIsAdminUser(): UseQueryResult<{ data: boolean }> {
  return useQuery([QUERY_KEYS.isAdminUser], () => getIsAdminUser(), {
    enabled: true,
  });
}
