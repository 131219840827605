import { useMutation, useQueryClient } from "react-query";
import api from "../../../service/api";

const url = process.env.REACT_APP_BASE_URL;
// const url = "https://shared-d-we-api.azure-api.net/bfm/bff/v1";

export const clientSearchByText = async (clientName: string) => {
  const res = await api.get(`${url}/clientssearch/${clientName}`);

  return res.data.map((item) => ({
    ...item,
    label: item?.name,
    value: item?.id,
  }));
};

// export function useGetClient(clientName?: string): UseQueryResult<any> {
//   return useQuery(
//     [QUERY_KEYS.clientList],
//     () => clientSearchByText(clientName),
//     {
//       enabled: true,
//     }
//   );
// }

export function useGetClient() {
  const queryClient = useQueryClient();

  return useMutation(clientSearchByText, {
    onSuccess: () => {
      // queryClient.invalidateQueries(QUERY_KEYS.appSettings);
    },
    onError: (error) => {},
  });
}
