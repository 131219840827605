import { Spinner, SpinnerSize } from "@fluentui/react";
import debounce from "debounce-promise";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import AsyncReactSelect from "react-select/async";

import { useAtom, useSetAtom } from "jotai";
import { clientSearchByText } from "../../../../hooks/data/mutations/useGetClient";
import useAppTheme from "../../../../hooks/useAppTheme";
import { resultsState, selectedClientState } from "../../../../store";

const AsyncClientComboBox = () => {
  const theme = useAppTheme();

  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useAtom(selectedClientState);
  const setResults = useSetAtom(resultsState);

  const onChange = (value, props) => {
    setResults({});
    if (props.action === "clear") {
      setSelectedClient({});
    }
    if (value) {
      setSelectedClient(value);
    }
  };
  return (
    <AsyncReactSelect
      cacheOptions
      value={selectedClient}
      placeholder={t("greco.form.searchPlaceholder")}
      loadingMessage={() => t("greco.loading")}
      noOptionsMessage={() => t("greco.noResults")}
      styles={{
        container: (p) => ({
          ...p,
          width: "100%",
        }),
        menuPortal: (p) => ({
          ...p,
          background: theme.palette.neutralLighter,
          zIndex: 9999999,
        }),
        menu: (p) => ({
          ...p,
          zIndex: 9999,
          background: theme.palette.neutralLighter,
        }),
        input: (p) => ({
          ...p,
          color: (function () {
            return theme.palette.black;
          })(),
        }),
        control: (p, state) => {
          return {
            ...p,
            width: "100%",
            border: "none",
            background: "transparent",
            borderBottom: state.isFocused
              ? `1px solid ${theme.palette.themeDark} !important`
              : `1px solid ${theme.palette.neutralDark} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
            }),
            borderRadius: "none",
            cursor: "pointer",
          };
        },

        option: (p, state) => ({
          ...p,
          background:
            state.isSelected || state.isFocused
              ? theme.palette.neutralLighter
              : theme.palette.white,
          "&:hover": {
            background: theme.palette.neutralLighter,
          },
          color: theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
          textAlign: "left",
        }),

        singleValue: (p, state) => ({
          ...p,
          color: state.isDisabled
            ? theme.palette.neutralSecondaryAlt
            : theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
        }),
        dropdownIndicator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
        noOptionsMessage: (p, state) => ({
          ...p,
          background: theme.palette.white,
        }),
        indicatorSeparator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
      }}
      onChange={onChange}
      isDisabled={false}
      components={{
        Input,
        LoadingIndicator,
      }}
      options={[
        {
          label: "Test",
          value: "Item",
        },
      ]}
      isClearable={!!selectedClient?.name}
      loadOptions={debounce(async (input: string) => {
        const trimmedFilter = input.trim();
        if (trimmedFilter.length === 0) return [];

        const clientData = await clientSearchByText(input);
        return clientData;
      }, 500)}
    />
  );
};

const Input = (props) => <components.Input {...props} maxLength={100} />;

const LoadingIndicator = () => {
  return (
    <Spinner size={SpinnerSize.medium} styles={{ root: { marginRight: 9 } }} />
  );
};

export default AsyncClientComboBox;
