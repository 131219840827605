import { format } from "date-fns";

export const formatDate = (date: string) => {
  return date !== null && date !== ""
    ? format(new Date(date), "yyyy-MM-dd")
    : "";
};

export const formatDateString = (date: string) => {
  return date !== null && date !== ""
    ? format(new Date(date), "dd.MM.yyyy")
    : "";
};
export const formatFullDate = (date: string) => {
  return date !== null && date !== ""
    ? format(new Date(date), "dd.MM.yyyy hh:mm:ss")
    : "";
};

export const formatDateWithTime = (date: Date) =>
  format(date, "yyyy_MM_dd_kk_mm_ss");

export const firstLetterUpper = (string: string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatNumber = (n: number, minPrecision = 2, maxPrecision = 2) => {
  if (!n) return null;
  return n.toLocaleString("de-DE", {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  });
};

export const getTaxonomyValue = (
  options: {
    value: string | number;
    label: string;
  }[],
  fieldValue: string,
  t: any
) => t(options?.find((option) => option.value === fieldValue)?.label);

export const urlFormat = (url?: string) => {
  if (!url) return "";

  let result = url;
  if (url.charAt(url.length - 1) === "/") {
    result = url.slice(0, -1);
  }
  return result;
};

export const openLinkInNewTab = (path: string) => {
  return window.open(path);
};
