import { ThemeProvider } from "@greco/components";
import { useAtomValue } from "jotai";
import {
  ThemeProvider as StyledThemeProvider,
  styled,
} from "styled-components";
import "./App.css";
import AppHeader from "./components/AppHeader/AppHeader";
import { MainPage } from "./pages/MainPage/MainPage";
import { darkModeState, panelIsOpenState } from "./store";
import { darkStyledTheme, styledTheme } from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import grecoTriangleBg from "./assets/GrECoGraphicTriangles.svg";
import { AppSubheader } from "./components/AppHeader/AppSubheader/AppSubheader";
import { useGetIsAdminUser } from "./hooks/data/queries/useGetIsAdminUser";

function App() {
  const panelIsOpen = useAtomValue(panelIsOpenState);
  const darkMode = useAtomValue(darkModeState);
  return (
    <div className="App">
      <ThemeProvider isDarkMode={false}>
        <StyledThemeProvider theme={darkMode ? darkStyledTheme : styledTheme}>
          <AppHeader />
          <Container $isPanelOpen={panelIsOpen}>
            <AppSubheader />
            <MainPage />
          </Container>
          <ToastContainer />
        </StyledThemeProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

const Container = styled("div")<{ $isPanelOpen: boolean }>`
  position: absolute;
  overflow: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  width: ${({ $isPanelOpen }) =>
    $isPanelOpen ? "calc(100% - 340px)" : "100%"};
  background-color: ${(props) => props.theme.palette.themeBackground};

  background-image: url("${grecoTriangleBg}");
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
`;
