import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  contactPersonsState,
  resultsState,
  selectedClientState,
} from "../../store";
import Info from "./components/Info/Info";
import { Questionnaire } from "./components/Questionare";
import Results from "./components/Results/Results";
import { getQuestionnaireData } from "./data/data";
import { useEffect } from "react";
import { toast } from "react-toastify";

export function MainPage() {
  const results = useAtomValue(resultsState);
  const contactPersons = useAtomValue(contactPersonsState);

  const { t } = useTranslation();
  const questionnaireData = getQuestionnaireData(t);

  const allFilled =
    Object.keys(results).length === questionnaireData?.length &&
    Object.values(results).every((obj) => obj.hasOwnProperty("evaluation")) &&
    contactPersons.trim();

  const selectedClient = useAtomValue(selectedClientState);

  const showQuestions =
    !!Object.keys(selectedClient).length && contactPersons.trim();

  return (
    <>
      <Info />
      <Container visible={!!showQuestions}>
        <Content>
          <Title>{t("esg.questionnaireTitle.label")}</Title>
          {questionnaireData.map((questionGroups, index) => {
            const questionGroupNo = index + 1;

            return (
              <Questionnaire
                key={questionGroups.id}
                questionGroups={questionGroups}
                questionGroupNo={questionGroupNo}
              />
            );
          })}
          {allFilled ? <Results data={results} /> : null}
        </Content>
      </Container>
    </>
  );
}

const Container = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  max-width: 1200px;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  padding: 50px 20px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px;
  background: ${({ theme }) => theme.palette.white};
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.palette.black};
  margin: 20px auto 40px;
`;
