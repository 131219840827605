import { UseQueryResult, useQuery } from "react-query";
import { QUERY_KEYS } from "../../../library/shared";
import api from "../../../service/api";

type Params = {
  fromDate: string;
  toDate: string;
};
export const getExcelReport = async ({ fromDate, toDate }: Params) => {
  const res = await api.get(`/questionary/exportexcel/${fromDate}/${toDate}`);

  return res.data;
};

export function useGetExcelReport(data: Params): UseQueryResult<{
  name: string;
  url: string;
}> {
  console.log(data, "data here");
  return useQuery(
    [QUERY_KEYS.excelReport, data.fromDate, data.toDate],
    () => getExcelReport(data),
    {
      enabled: !!data.fromDate && !!data.toDate,
    }
  );
}
