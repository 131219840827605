import { UseQueryResult, useQuery } from "react-query";
import { getUserDetails } from "../../../service/GraphService";
import { IUser } from "../../../library/types";
import { QUERY_KEYS } from "../../../library/shared";

export function useUserData(userId?: string): UseQueryResult<IUser> {
  return useQuery([QUERY_KEYS.userData], () => getUserDetails(userId), {
    enabled: true,
  });
}
