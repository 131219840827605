import { useMutation } from "react-query";
import api from "../../../service/api";

const url = process.env.REACT_APP_BASE_URL;

type Props = {
  clientId: string | number;
  resultId: string | number;
};
export const exportPdf = async ({ clientId, resultId }: Props) => {
  return await api.patch(
    `${url}/questionary/exportpdf/${clientId}/${resultId}`
  );
};

export function useExportPdf() {
  return useMutation(exportPdf, {
    onSuccess: () => {},
    onError: (error) => {
      console.log(error, "useExportPdf");
    },
  });
}
