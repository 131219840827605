import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IResults } from "../../../../library/types";
import ResultItem from "./ResultItem";

type Props = {
  data: IResults;
};

function Results({ data }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Title>{t("esg.questionnaireResults.label")}</Title>
        {Object.keys(data).map((key, index) => {
          const result = data[key];

          return <ResultItem result={result} key={index} resultNo={index} />;
        })}
      </Container>
    </>
  );
}

export default Results;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 50px 20px 20px;
  color: ${({ theme }) => theme.palette.black};
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 16px;
  text-transform: uppercase;
  padding: 20px;
  font-weight: 600;
`;
