import {
  DatePicker,
  DefaultButton,
  defaultDatePickerStrings,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetExcelReport } from "../../../../hooks/data/queries/useGetExcelReport";
import useAppTheme from "../../../../hooks/useAppTheme";
import { formatDate, openLinkInNewTab } from "../../../../library/utils";

type Props = {
  toggleOpen: () => void;
  isOpen: boolean;
};

function ExcelReportDialog({ toggleOpen, isOpen }: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();

  const [fromDate, setFromDate] = React.useState<Date | null>(null);
  const [toDate, setToDate] = React.useState<Date | null>(null);
  const [payload, setPayload] = React.useState<any>({
    fromDate: "",
    toDate: "",
  });

  const { data: excelReport, isLoading } = useGetExcelReport(payload);

  return (
    <Dialog
      minWidth={400}
      maxWidth={400}
      dialogContentProps={{
        type: DialogType.normal,
        title: t("esg.generateExcelReport.label"),
        titleProps: {
          style: {
            color: theme.palette.neutralDark,
          },
        },
      }}
      styles={{
        main: {
          padding: 20,
          background: theme.palette.neutralLighterAlt,
        },
      }}
      hidden={!isOpen}
      onDismiss={toggleOpen}
    >
      <div>
        <DatePicker
          value={fromDate}
          isRequired
          label={t("esg.fromDate.label")}
          placeholder={t("greco.form.selectPlaceholder")}
          ariaLabel="Select a date"
          textField={{
            errorMessage: fromDate ? "" : t("greco.form.errors.required"),
          }}
          onSelectDate={(date) => {
            setFromDate(date);
          }}
          strings={defaultDatePickerStrings}
        />
        <DatePicker
          value={toDate}
          isRequired
          label={t("esg.toDate.label")}
          placeholder={t("greco.form.selectPlaceholder")}
          ariaLabel="Select a date"
          // DatePicker uses English strings by default. For localized apps, you must override this prop.
          strings={defaultDatePickerStrings}
          onSelectDate={(date) => {
            setToDate(date);
          }}
          textField={{
            errorMessage: toDate ? "" : t("greco.form.errors.required"),
          }}
        />
      </div>
      <DialogFooter>
        {excelReport ? (
          <PrimaryButton
            onClick={() => {
              openLinkInNewTab(excelReport.url);
            }}
          >
            {t("esg.downloadExcel.label")}
          </PrimaryButton>
        ) : (
          <PrimaryButton
            disabled={isLoading}
            onClick={() => {
              setPayload({
                fromDate: formatDate(fromDate.toLocaleString()),
                toDate: formatDate(toDate.toLocaleString()),
              });
            }}
            text={t("esg.generate.label")}
          />
        )}
        <DefaultButton
          disabled={isLoading}
          onClick={toggleOpen}
          text={t("greco.cancel")}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default ExcelReportDialog;
