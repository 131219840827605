import { IQuestionnaire, IResults } from "../../../library/types";

export const prepareSaveResultsPayload = (
  results: IResults,
  questionnaire: IQuestionnaire[]
) => {
  const result = {};
  Object.keys(results).forEach((key) => {
    const answers = Object.values(results[key].answers);

    result[key] = {
      ...results[key],
      answers,
      questions: questionnaire
        .find((q) => q.id.toString() === key.toString())
        .questions.slice(0, answers.length)
        .map((q) => q.question),
      questionsExplained: questionnaire
        .find((q) => q.id.toString() === key.toString())
        .questions.slice(0, answers.length)
        .map((q) => q.questionExplained),
    };
  });
  return result;
};
