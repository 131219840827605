import { GrecoHeader } from "@greco/components";

import i18n from "../../i18n";

import Logo from "../../assets/GrECo_Logo_small.jpg";

import { logout } from "../../service/GraphService";
import { getHeaderConfig } from "./utils";

import { IDropdownOption } from "@fluentui/react";
import { useTranslation } from "react-i18next";

import { useAtom, useSetAtom } from "jotai";
import { useUserData } from "../../hooks/data/queries/useUserData";
import { STORAGE_KEYS } from "../../library/shared";
import { LocalStorage } from "../../service/storage";
import { darkModeState, panelIsOpenState } from "../../store";

const AppHeader = () => {
  const { t } = useTranslation();

  const [_, setDarkMode] = useAtom(darkModeState);
  const setPanelIsOpen = useSetAtom(panelIsOpenState);

  const { data: user } = useUserData();

  const headerConfig = getHeaderConfig(`Environmental, social and governance`);

  return (
    <GrecoHeader
      headerConfig={headerConfig}
      panelStatus={setPanelIsOpen}
      user={user}
      logo={Logo}
      logout={logout}
      darkMode={(isDarkMode: boolean) => {
        setDarkMode(isDarkMode);
        LocalStorage.set(STORAGE_KEYS.darkMode, isDarkMode);
      }}
      languageOptions={[
        {
          key: "en-GB",
          text: t("Language.EN_GB"),
        },
        {
          key: "de-AT",
          text: t("Language.DE_AT"),
        },
      ]}
      defaultLanguage={i18n.resolvedLanguage}
      onChangeLanguage={async (
        __event: any,
        languageOption?: IDropdownOption
      ) => {
        if (!languageOption) return;

        LocalStorage.set(STORAGE_KEYS.appLang, languageOption?.key || "");
        i18n.changeLanguage(languageOption.key as string);
      }}
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        helpPanelStrings: {
          panelTitle: t("header.Help"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
          darkModeToggleLabel: t("header.DarkMode"),
          allwaysShowTooltipsToggleLabel: t("header.AlwaysShowTooltips"),
          selectLanguageLabel: t("header.SelectLanguage"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
        searchPlaceholder: t("header.Search"),
      }}
    />
  );
};

export default AppHeader;
