import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { IQuestionnaire } from "../../../library/types";
import { selectedClientState } from "../../../store";
import { QuestionItem } from "./QuestionItem";

type Props = {
  questionGroups: IQuestionnaire;
  questionGroupNo: number;
};

export const Questionnaire = ({ questionGroups, questionGroupNo }: Props) => {
  const selectedClient = useAtomValue(selectedClientState);

  const initialQuestionLevel = useMemo(() => {
    return [questionGroups.questions[0].id];
  }, [questionGroups.questions]);

  const [questionLevels, setQuestionLevels] = useState(initialQuestionLevel);

  const handleSetLevels = useCallback((value) => {
    setQuestionLevels(value);
  }, []);

  useEffect(() => {
    setQuestionLevels(initialQuestionLevel);
  }, [selectedClient]);

  const questionGroupId = questionGroups.id;
  const questionGroupName = questionGroups.questionGroupName;

  return (
    <Container>
      {questionLevels.map((level, index) => {
        const question = questionGroups.questions.find(
          (q: any) => q.id === level
        );

        const maxLevel = questionGroups.questions.length;

        const hasNext = !questionLevels.includes(maxLevel);

        const subNumber = index > 1 ? `1.` : "";
        const questionItemNo = index ? `${subNumber}1` : "";
        return (
          <QuestionItem
            questionGroupName={questionGroupName}
            questionGroupId={questionGroupId}
            questionItemNo={questionItemNo}
            questionGroupNo={questionGroupNo}
            currentLevel={level}
            questionLevels={questionLevels}
            question={question}
            setQuestionLevels={handleSetLevels}
            key={level}
            hasNext={hasNext}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
`;
